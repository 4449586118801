// Move the axeptio overlay to a div we control so we can add turbo-permanent to it
// This is a hack to make the overlay work with turbo

import { Controller } from '@hotwired/stimulus'
import { useMutation } from 'stimulus-use'

export default class extends Controller {
  static targets = ['consent']

  connect () {
    useMutation(this, { childList: true })
  }

  mutate (entries) {
    entries.forEach((mutation) => {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          if ((node instanceof window.HTMLElement)) {
            if ((node.id === 'axeptio_overlay')) {
              this.consentTarget.appendChild(node)
            }
          }
        })
      }
    })
  }
}
