import { Controller } from '@hotwired/stimulus'
import { AnalyticsBrowser } from '@segment/analytics-next'
import throttle from 'lodash/throttle'

export default class extends Controller {
  static throttleDelay = 1000
  static scrollPercentageEventPrefix = 'web_scroll_percentage_'

  static values = {
    consentNeeded: { type: Boolean, default: false },
    consentGiven: { type: Boolean, default: false },
    sendPageView: { type: Boolean, default: false },
    sendIdentify: { type: Boolean, default: false },
    writeKey: { type: String },
    disable: { type: Boolean, default: true },
    identify: { type: Boolean, default: false },
    currentUserId: { type: String },
    currentUserName: { type: String },
    currentUserEmail: { type: String },
    buttonName: { type: String, default: '' },
    trackScrollName: { type: String, default: null }
  }

  initialize () {
    this.recordScrollPercentage = throttle(this.recordScrollPercentage, this.constructor.throttleDelay).bind(this)
    this.trackScrollPercentage = this.trackScrollPercentage.bind(this)
    this.maxScroll = 0
  }

  initSegment () {
    this.respecting_consent(() => {
      this.analytics = AnalyticsBrowser.load(
        {
          writeKey: this.getAnalyticsConfig().getAttribute('data-analytics-write-key')
        },
        {
          obfuscate: true,
          disable: this.getAnalyticsConfig().getAttribute('data-disable-analytics')
        }
      )

      this.identify()
      this.trackPageView()
    })
  }

  connect () {
    this.initSegment()
    if (this.trackScrollNameValue) {
      window.addEventListener('scroll', this.recordScrollPercentage, { passive: true })
      document.addEventListener('visibilitychange', this.trackScrollPercentage)
    }
  }

  disconnect () {
    window.removeEventListener('scroll', this.recordScrollPercentage)
    document.removeEventListener('visibilitychange', this.trackScrollPercentage)
  }

  consentGivenValueChanged () {
    if (this.consentGivenValue) {
      this.initSegment()
    }
  }

  identify () {
    this.respecting_consent(() => {
      if (this.sendIdentifyValue) {
        this.analytics.identify(
          this.currentUserIdValue,
          {
            name: this.currentUserNameValue,
            email: this.currentUserEmailValue
          }
        )
      }
    })
  }

  trackPageView () {
    this.respecting_consent(() => {
      if (this.sendPageViewValue) {
        const pageName = document.querySelector('meta[name="analytics-page-name"]')?.getAttribute('content')
        this.analytics.page(pageName, this.parsedPageProperties())
      }
    })
  }

  trackClick () {
    this.respecting_consent(() => {
      const eventName = `web_click_${this.buttonNameValue}_button`
      this.analytics.track(eventName)
    })
  }

  recordScrollPercentage () {
    const scrollHeight = document.documentElement.scrollHeight - window.innerHeight
    const scrollTop = window.scrollY
    const percentage = (scrollTop / scrollHeight) * 100

    if (percentage > this.maxScroll) {
      this.maxScroll = percentage
    }
  }

  trackScrollPercentage () {
    this.respecting_consent(() => {
      if (document.visibilityState === 'hidden') {
        const eventName = `${this.constructor.scrollPercentageEventPrefix}${this.trackScrollNameValue}`
        this.analytics.track(eventName, { scrolled_percent: this.maxScroll })
      }
    })
  }

  getAnalyticsConfig () {
    return document.getElementById('analytics-config')
  }

  parsedPageProperties () {
    const pageProperties = document.querySelector('meta[name="analytics-page-properties"]')?.getAttribute('content')
    return pageProperties ? JSON.parse(pageProperties) : {}
  }

  respecting_consent (cb) {
    if (!this.consentNeededValue || this.consentGivenValue) {
      cb()
    }
  }
}
