import { Controller } from '@hotwired/stimulus'
import debounce from "lodash/debounce"

const DEBOUNCE_WAIT = 200

export default class extends Controller {
  static targets = ['output', 'coordinates']

  initialize() {
    this.dispatchMapMoved = debounce(this.dispatchMapMoved.bind(this), DEBOUNCE_WAIT);
    this.dispatchPolygonSelected = debounce(this.dispatchPolygonSelected.bind(this), DEBOUNCE_WAIT);
    this.handleMessage = debounce(this.handleMessage.bind(this), DEBOUNCE_WAIT);
  }

  connect () {
    window.addEventListener('message', this.handleMessage.bind(this))
  }

  handleMessage (event) {
    if (event.data.type === 'travelTo') {
      this.outputTarget.value = JSON.stringify(event.data.payload)
      this.outputTarget.dispatchEvent(new Event('change'))
    }
  }

  dispatchMapMoved () {
    window.parent.postMessage({
      type: 'map:moved',
      payload: this.coordinatesTarget.value
    }, '*')
  }

  dispatchPolygonSelected () {
    window.parent.postMessage({
      type: 'polygon:selected',
      payload: this.coordinatesTarget.value
    }, '*')
  }

  dispatchMapLoaded () {
    window.parent.postMessage({
      type: 'map:loaded'
    }, '*')
  }
}