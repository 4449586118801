import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'refreshInput', 'submitter']

  submit () {
    if (!this.hasFormTarget) return
    const submitter = this.hasSubmitterTarget ? this.submitterTarget : undefined

    this.formTarget.requestSubmit(submitter)
  }

  // For cases where we want to refresh the form to apply display changes
  // but not submit the form, we use a hidden input to indicate to the controller
  // that the view should be re-rendered and the form object should not be submitted
  refresh () {
    this.refreshInputTarget.removeAttribute('disabled')
    this.formTarget.setAttribute('novalidate', 'novalidate')
    this.submit()
  }
}
